<template>
  <el-dialog
    title="修改订单"
    :visible.sync="dialogVisible"
    append-to-body
    center
    width="500px"
    :before-close="handleClose">

    <el-form :model="postForm">
      <el-form-item label="币种" :label-width="formLabelWidth">
        <el-select v-model="postForm.symbol" @change="handleChangeSymbol">
          <el-option v-for="(item, idx) in chains" :key="idx" :label="item.symbol" :value="item.symbol"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="涨跌" :label-width="formLabelWidth">
        <el-select v-model="postForm.buy_up">
          <el-option label="涨" :value="1"></el-option>
          <el-option label="跌" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="规格" :label-width="formLabelWidth">
        <el-select v-model="postForm.time">
          <el-option v-for="(item, idx) in specification" :key="idx" :label="item.time" :value="item.time"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="下单价格" :label-width="formLabelWidth">
        <el-input v-model="postForm.chain_price"></el-input>
      </el-form-item>
      <el-form-item label="下单时间" :label-width="formLabelWidth">
        <el-date-picker
          v-model="postForm.create_time"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择下单时间" @change="handleChangeCreateTime">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结算时间" :label-width="formLabelWidth">
        <el-date-picker
          v-model="postForm.will_settle_time"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择结算时间"
          ref="settleTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="交易状态" v-if="postForm.status == 1" :label-width="formLabelWidth">
        <el-select v-model="postForm.trade_status">
          <el-option label="盈利" :value="3"></el-option>
          <el-option label="亏损" :value="4"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";
import { coverTime } from '@/utils/utils'

export default {
  name: "DialogOrderEdit",
  data() {
    return {
      dialogVisible: false,
      formLabelWidth: '120px',
      orderId: 0,
      searchForm: {
        id: 0,
        page: 1,
        total: 0,
        limit: 20
      },
      chains: [],
      specification: [],
      postForm: {
        id: 0,
        symbol: '',
        buy_up: 1,
        time:150,
        chain_price: '',
        trade_status: 3,
        create_time: '',
        will_settle_time: ''
      }
    }
  },
  methods: {
    coverTime,
    toggle(orderId) {
      this.orderId = orderId
      this.searchForm.id = orderId
      this.dialogVisible = true
      this.fetchData()
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleCurrentChange(e) {
      console.log(e)
      this.fetchData()
    },
    fetchData() {
      const that = this
      requestHttp('agent/get_order_detail', this.searchForm).then((res) => {
        if (res.code == 200) {
          that.postForm = res.data.detail
          that.chains = res.data.chains
          that.specification = res.data.specification
        } else {
          that.$message.error(res.message)
        }
      })
    },
    handleChangeSymbol() {
      const that = this
      that.chains.forEach((item) => {
        if (that.postForm.symbol == item.symbol) {
          that.postForm.chain_price = item.price
        }
      })
    },
    handleChangeCreateTime() {
      const date = new Date(this.postForm.create_time);
      date.setTime(date.getTime() + this.postForm.time);
      this.postForm.will_settle_time = this.coverTime(date)
    },
    handleSubmit() {
      const that = this
      requestHttp('agent/save_order', this.postForm).then((res) => {
        if (res.code == 200) {
          that.fetchData()
          that.$emit('change');
          that.$message.success(res.message)
          that.handleClose()
        } else {
          that.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./dialog-order-log.vue?vue&type=template&id=98e92ab4&scoped=true"
import script from "./dialog-order-log.vue?vue&type=script&lang=js"
export * from "./dialog-order-log.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98e92ab4",
  null
  
)

export default component.exports
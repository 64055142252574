<template>
  <el-dialog
    title="发送消息"
    :visible.sync="dialogVisible"
    center
    width="1000px"
    :before-close="handleClose">

    <el-form label-width="80px">
      <el-form-item label="用户">
        {{userId}}
      </el-form-item>
      <el-form-item label="消息内容">
        <el-input type="textarea" v-model="postForm.content" placeholder=""/>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>

    <el-table border :data="logDatas">
      <el-table-column
          prop="message_add_time"
          width="160"
          label="发送时间">
      </el-table-column>
      <el-table-column
        prop="message_content"
        show-overflow-tooltip
        label="内容">
      </el-table-column>
      <el-table-column
        prop="message_content"
        show-overflow-tooltip
        width="70"
        label="操作">
        <template slot-scope="scope">
          <el-button type="danger" size="small" @click="handleDelete(scope.row.message_id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <br />
    <el-pagination
        background
        layout="prev, pager, next, sizes"
        :page-sizes="[20, 100, 300, 400, 5000]"
        :current-page.sync="logForm.page"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :total="logForm.total"
        :page-size="logForm.limit">
    </el-pagination>

  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";

export default {
  name: "DialogSendMessage",
  data() {
    return {
      dialogVisible: false,
      userId: 0,
      messageId: '',
      datas: [],
      activeTabsName: 'first',
      postForm: {
        user_id: '',
        content: ''
      },
      logDatas: [],
      logForm: {
        user_id: '',
        page: 1,
        total: 0,
        limit: 20
      }
    }
  },
  methods: {
    toggle(userId) {
      this.userId = userId
      this.postForm.user_id = userId
      this.logForm.user_id = userId
      this.dialogVisible = true
      this.fetchData()
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleSubmit() {
      const that = this
      this.$confirm('确认发送？',  '提示').then(function () {
        requestHttp('agent/send_message', that.postForm).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.handleClose()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    handleDelete(message_id) {
      const that = this
      this.$confirm('确认删除？',  '提示').then(function () {
        requestHttp('agent/delete_message', {message_id: message_id}).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.handleClose()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    fetchData() {
      const that = this
      requestHttp('agent/get_message_list', this.logForm).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          this.logDatas = res.data.data
          this.logForm.page = res.data.current_page
          this.logForm.total = res.data.total
          this.logForm.limit = res.data.per_page
        }
      })
    },
    handleCurrentChange(e) {
      console.log(e)
      this.fetchData()
    },
    handleSizeChange(val) {
      this.logForm.page = 1
      this.logForm.limit = val
      this.fetchData()
    },
  }
}
</script>

<style scoped>
  .green {
    color: #35b65a !important;
  }
  .red {
    color: #ff494a !important;
  }
</style>

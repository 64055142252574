<template>
  <el-dialog
    title="查重"
    :visible.sync="dialogVisible"
    center
    width="600"
    append-to-body
    :before-close="handleClose">

    <el-form :inline="true" :model="searchForm">
      <el-form-item label="搜索">
        <el-input type="textarea" v-model="searchForm.keyword" placeholder="关键字"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
      </el-form-item>
    </el-form>

    <div style="color: red; font-weight: bold">查询关键字：{{keyword}}</div>
    <div style="padding: 20px; min-height: 400px; line-height: 25px;">
      <div v-html="content"></div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";

export default {
  name: "DialogWithdrawLog",
  data() {
    return {
      dialogVisible: false,
      userId: 0,
      keyword: '',
      content: '',
      searchForm: {
        keyword: '',
        user_id: ''
      }
    }
  },
  methods: {
    toggle(user_id) {
      this.dialogVisible = true
      if (user_id) {
        this.searchForm.user_id = user_id
        this.fetchData()
      }
    },
    handleSearch() {
      this.fetchData()
    },
    handleClose() {
      this.dialogVisible = false
    },
    fetchData() {
      const that = this
      that.content = '';
      requestHttp('agent/duplicate_check', that.searchForm).then((res) => {
        if (res.code == 200) {
          that.keyword = res.data.keyword
          that.content = res.data.content
        } else {
          that.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>

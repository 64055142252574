<template>
  <el-dialog
    title="ip关联"
    :visible.sync="dialogVisible"
    center
    width="800px"
    :append-to-body="appendToBody"
    :before-close="handleClose">

    <el-form>
      <el-form-item label="IP">
        <el-input v-model="searchForm.ip" style="width: 150px; margin-right: 10px" placeholder="IP"/>
        <el-button type="danger" @click="fetchData">搜索</el-button>
      </el-form-item>
    </el-form>

    <el-table
      height="300"
      :data="datas"
      border>
      <el-table-column
        prop="user_id"
        label="账号"
        align="center"
        width="150">
        <template slot-scope="scope">
          {{scope.row.user_id}}<br>({{scope.row.nickname}})
        </template>
      </el-table-column>
      <el-table-column
        prop="line_id"
        label="线路"
        align="center"
        width="100">
        <template slot-scope="scope">
          {{scope.row.line_id}}
        </template>
      </el-table-column>
      <el-table-column
        prop="ip"
        label="IP"
        width="220">
        <template slot-scope="scope">
          注册：{{scope.row.register_ip}}<el-button type="primary" size="mini" @click="copyVal(scope.row.register_ip)">复制</el-button>
          <br> 最后：{{scope.row.last_login_ip}}<el-button type="warning" size="mini" @click="copyVal(scope.row.register_ip)">复制</el-button>
          <br> IP最后：{{scope.row.ip_last_time}}
        </template>
      </el-table-column>
      <el-table-column
        prop="bank_card"
        label="银行">
        <template slot-scope="scope">
          账号：{{scope.row.bank_card}} <br> 户名：{{scope.row.real_name}}
        </template>
      </el-table-column>
    </el-table>
    <br/>
    <el-pagination
      align="right"
      background
      layout="prev, pager, next"
      :current-page.sync="searchForm.page"
      @current-change="handleCurrentChange"
      :total="searchForm.total"
      :page-size="searchForm.limit">
    </el-pagination>

    <!--
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="fetchData">刷 新</el-button>
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </div>
    -->
    <dialog-order-edit ref="dialogOrderEdit" @change="fetchData"></dialog-order-edit>
  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";
import DialogOrderEdit from "@/views/chat/components/dialog-order-edit";
import { thumb } from "@/utils/utils";

export default {
  name: "DialogOrderLog",
  components: {
    DialogOrderEdit
  },
  props: {
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      userId: 0,
      searchForm: {
        ip: "",
        page: 1,
        total: 0,
        limit: 20
      },
      datas: []
    }
  },
  methods: {
    thumb,
    toggle(ip) {
      this.searchForm.ip = ip
      this.dialogVisible = true
      this.fetchData()
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleCurrentChange(e) {
      console.log(e)
      this.fetchData()
    },
    copyVal(val) {
      let context = val
      // 创建输入框元素
      let oInput = document.createElement('textarea');
      // 将想要复制的值
      oInput.innerHTML = context;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand('Copy');
      // 弹出复制成功信息
      this.$message.success('复制成功');
      // 复制后移除输入框
      oInput.remove();
    },
    fetchData() {
      const that = this
      that.datas = [];
      requestHttp('agent/get_accounts_by_ip', this.searchForm).then((res) => {
        if (res.code == 200) {
          that.datas = res.data.data
          that.searchForm.page = res.data.current_page
          that.searchForm.total = res.data.total
          that.searchForm.limit = res.data.per_page
        } else {
          that.$message.error(res.message)
        }
      })
    },
    showEdit(row) {
      this.$refs.dialogOrderEdit.toggle(row.id)
    }
  }
}
</script>

<style scoped>

</style>

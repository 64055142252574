let face = {
	faces: [],
	faceObject: {
		1: {
			status: 2,
			path: 'Expression_1@2x.png',
		},
		2: {
			status: 2,
			path: 'Expression_2@2x.png',
		},
		3: {
			status: 2,
			path: 'Expression_3@2x.png',
		},
		4: {
			status: 2,
			path: 'Expression_4@2x.png',
		},
		5: {
			status: 2,
			path: 'Expression_5@2x.png',
		},
		6: {
			status: 2,
			path: 'Expression_6@2x.png',
		},
		7: {
			status: 2,
			path: 'Expression_7@2x.png',
		},
		8: {
			status: 2,
			path: 'Expression_8@2x.png',
		},
		9: {
			status: 2,
			path: 'Expression_9@2x.png',
		},
		10: {
			status: 2,
			path: 'Expression_10@2x.png',
		},
		11: {
			status: 2,
			path: 'Expression_11@2x.png',
		},
		12: {
			status: 2,
			path: 'Expression_12@2x.png',
		},
		13: {
			status: 2,
			path: 'Expression_13@2x.png',
		},
		14: {
			status: 2,
			path: 'Expression_14@2x.png',
		},
		15: {
			status: 2,
			path: 'Expression_15@2x.png',
		},
		16: {
			status: 2,
			path: 'Expression_16@2x.png',
		},
		17: {
			status: 2,
			path: 'Expression_17@2x.png',
		},
		18: {
			status: 2,
			path: 'Expression_18@2x.png',
		},
		19: {
			status: 2,
			path: 'Expression_19@2x.png',
		},
		20: {
			status: 2,
			path: 'Expression_20@2x.png',
		},
		21: {
			status: 2,
			path: 'Expression_21@2x.png',
		},
		22: {
			status: 2,
			path: 'Expression_22@2x.png',
		},
		23: {
			status: 2,
			path: 'Expression_23@2x.png',
		},
		24: {
			status: 2,
			path: 'Expression_24@2x.png',
		},
		25: {
			status: 2,
			path: 'Expression_25@2x.png',
		},
		26: {
			status: 2,
			path: 'Expression_26@2x.png',
		},
		27: {
			status: 2,
			path: 'Expression_27@2x.png',
		},
		28: {
			status: 2,
			path: 'Expression_28@2x.png',
		},
		29: {
			status: 2,
			path: 'Expression_29@2x.png',
		},
		30: {
			status: 2,
			path: 'Expression_30@2x.png',
		},
		31: {
			status: 2,
			path: 'Expression_31@2x.png',
		},
		32: {
			status: 2,
			path: 'Expression_32@2x.png',
		},
		33: {
			status: 2,
			path: 'Expression_33@2x.png',
		},
		34: {
			status: 2,
			path: 'Expression_34@2x.png',
		},
		35: {
			status: 2,
			path: 'Expression_35@2x.png',
		},
		36: {
			status: 2,
			path: 'Expression_36@2x.png',
		},
		37: {
			status: 2,
			path: 'Expression_37@2x.png',
		},
		38: {
			status: 2,
			path: 'Expression_38@2x.png',
		},
		39: {
			status: 2,
			path: 'Expression_39@2x.png',
		},
		40: {
			status: 2,
			path: 'Expression_40@2x.png',
		},
		41: {
			status: 2,
			path: 'Expression_41@2x.png',
		},
		42: {
			status: 2,
			path: 'Expression_42@2x.png',
		},
		43: {
			status: 2,
			path: 'Expression_43@2x.png',
		},
		44: {
			status: 2,
			path: 'Expression_44@2x.png',
		},
		45: {
			status: 2,
			path: 'Expression_45@2x.png',
		},
		46: {
			status: 2,
			path: 'Expression_46@2x.png',
		},
		47: {
			status: 2,
			path: 'Expression_47@2x.png',
		},
		48: {
			status: 2,
			path: 'Expression_48@2x.png',
		},
		49: {
			status: 2,
			path: 'Expression_49@2x.png',
		},
		50: {
			status: 2,
			path: 'Expression_50@2x.png',
		},
		51: {
			status: 2,
			path: 'Expression_51@2x.png',
		},
		52: {
			status: 2,
			path: 'Expression_52@2x.png',
		},
		53: {
			status: 2,
			path: 'Expression_53@2x.png',
		},
		54: {
			status: 2,
			path: 'Expression_54@2x.png',
		},
		55: {
			status: 2,
			path: 'Expression_55@2x.png',
		},
		56: {
			status: 2,
			path: 'Expression_56@2x.png',
		},
		57: {
			status: 2,
			path: 'Expression_57@2x.png',
		},
		58: {
			status: 2,
			path: 'Expression_58@2x.png',
		},
		59: {
			status: 2,
			path: 'Expression_59@2x.png',
		},
		60: {
			status: 2,
			path: 'Expression_60@2x.png',
		},
		61: {
			status: 2,
			path: 'Expression_61@2x.png',
		},
		62: {
			status: 2,
			path: 'Expression_62@2x.png',
		},
		63: {
			status: 2,
			path: 'Expression_63@2x.png',
		},
		64: {
			status: 2,
			path: 'Expression_64@2x.png',
		},
		65: {
			status: 2,
			path: 'Expression_65@2x.png',
		},
		66: {
			status: 2,
			path: 'Expression_66@2x.png',
		},
		67: {
			status: 2,
			path: 'Expression_67@2x.png',
		},
		68: {
			status: 2,
			path: 'Expression_68@2x.png',
		},
		69: {
			status: 2,
			path: 'Expression_69@2x.png',
		},
		70: {
			status: 2,
			path: 'Expression_70@2x.png',
		},
		71: {
			status: 2,
			path: 'Expression_71@2x.png',
		},
		72: {
			status: 2,
			path: 'Expression_72@2x.png',
		},
		73: {
			status: 2,
			path: 'Expression_73@2x.png',
		},
		74: {
			status: 2,
			path: 'Expression_74@2x.png',
		},
		75: {
			status: 2,
			path: 'Expression_75@2x.png',
		},
		76: {
			status: 2,
			path: 'Expression_76@2x.png',
		},
		77: {
			status: 2,
			path: 'Expression_77@2x.png',
		},
		78: {
			status: 2,
			path: 'Expression_78@2x.png',
		},
		79: {
			status: 2,
			path: 'Expression_79@2x.png',
		},
		80: {
			status: 2,
			path: 'Expression_80@2x.png',
		},
		81: {
			status: 2,
			path: 'Expression_81@2x.png',
		},
		82: {
			status: 2,
			path: 'Expression_82@2x.png',
		},
		83: {
			status: 2,
			path: 'Expression_83@2x.png',
		},
		84: {
			status: 2,
			path: 'Expression_84@2x.png',
		},
		85: {
			status: 2,
			path: 'Expression_85@2x.png',
		},
		86: {
			status: 2,
			path: 'Expression_86@2x.png',
		},
		87: {
			status: 2,
			path: 'Expression_87@2x.png',
		},
		88: {
			status: 2,
			path: 'Expression_88@2x.png',
		},
		89: {
			status: 2,
			path: 'Expression_89@2x.png',
		},
		90: {
			status: 2,
			path: 'Expression_90@2x.png',
		},
		91: {
			status: 2,
			path: 'Expression_91@2x.png',
		},
		92: {
			status: 2,
			path: 'Expression_92@2x.png',
		},
		93: {
			status: 2,
			path: 'Expression_93@2x.png',
		},
		94: {
			status: 2,
			path: 'Expression_94@2x.png',
		},
		95: {
			status: 2,
			path: 'Expression_95@2x.png',
		},
		96: {
			status: 2,
			path: 'Expression_96@2x.png',
		},
		97: {
			status: 2,
			path: 'Expression_97@2x.png',
		},
		98: {
			status: 2,
			path: 'Expression_98@2x.png',
		},
		99: {
			status: 2,
			path: 'Expression_99@2x.png',
		},
		100: {
			status: 2,
			path: 'Expression_100@2x.png',
		},
		101: {
			status: 2,
			path: 'Expression_101@2x.png',
		},
		102: {
			status: 2,
			path: 'Expression_102@2x.png',
		},
		103: {
			status: 2,
			path: 'Expression_103@2x.png',
		},
		104: {
			status: 2,
			path: 'Expression_104@2x.png',
		},
		105: {
			status: 2,
			path: 'Expression_105@2x.png',
		},
		106: {
			status: 2,
			path: 'Expression_106@2x.png',
		},
		107: {
			status: 2,
			path: 'Expression_107@2x.png',
		},
		108: {
			status: 2,
			path: 'Expression_108@2x.png',
		},
		109: {
			status: 2,
			path: 'Expression_109@2x.png',
		},
		110: {
			status: 2,
			path: 'Expression_110@2x.png',
		},
		111: {
			status: 2,
			path: 'Expression_111@2x.png',
		},
		112: {
			status: 2,
			path: 'Expression_112@2x.png',
		},
		113: {
			status: 2,
			path: 'Expression_113@2x.png',
		},
		114: {
			status: 2,
			path: 'Expression_114@2x.png',
		},
		115: {
			status: 2,
			path: 'Expression_115@2x.png',
		},
		116: {
			status: 2,
			path: 'Expression_116@2x.png',
		},
		117: {
			status: 2,
			path: 'Expression_117@2x.png',
		},
		118: {
			status: 2,
			path: 'Expression_118@2x.png',
		},
		119: {
			status: 2,
			path: 'Expression_119@2x.png',
		},
		120: {
			status: 2,
			path: 'Expression_120@2x.png',
		},
		'Alien_Monster': {
			status: 1,
			path: 'new/Alien Monster.png',
		},
		'Alien': {
			status: 1,
			path: 'new/Alien.png',
		},
		'Anatomical_Heart': {
			status: 1,
			path: 'new/Anatomical Heart.png',
		},
		'Angry_Face_with_Horns': {
			status: 1,
			path: 'new/Angry Face with Horns.png',
		},
		'Angry_Face': {
			status: 1,
			path: 'new/Angry Face.png',
		},
		'Anguished_Face': {
			status: 1,
			path: 'new/Anguished Face.png',
		},
		'Anxious_Face_with_Sweat': {
			status: 1,
			path: 'new/Anxious Face with Sweat.png',
		},
		'Astonished_Face': {
			status: 1,
			path: 'new/Astonished Face.png',
		},
		'Backhand_Index_Pointing_Down': {
			status: 1,
			path: 'new/Backhand Index Pointing Down.png',
		},
		'Backhand_Index_Pointing_Left': {
			status: 1,
			path: 'new/Backhand Index Pointing Left.png',
		},
		'Backhand_Index_Pointing_Right': {
			status: 1,
			path: 'new/Backhand Index Pointing Right.png',
		},
		'Backhand_Index_Pointing_Up': {
			status: 1,
			path: 'new/Backhand Index Pointing Up.png',
		},
		'Beaming_Face_with_Smiling_Eyes': {
			status: 1,
			path: 'new/Beaming Face with Smiling Eyes.png',
		},
		'Bone': {
			status: 1,
			path: 'new/Bone.png',
		},
		'Brain': {
			status: 1,
			path: 'new/Brain.png',
		},
		'Call_Me_Hand': {
			status: 1,
			path: 'new/Call Me Hand.png',
		},
		'Cat_with_Tears_of_Joy': {
			status: 1,
			path: 'new/Cat with Tears of Joy.png',
		},
		'Cat_with_Wry_Smile': {
			status: 1,
			path: 'new/Cat with Wry Smile.png',
		},
		'Clapping_Hands': {
			status: 1,
			path: 'new/Clapping Hands.png',
		},
		'Clown_Face': {
			status: 1,
			path: 'new/Clown Face.png',
		},
		'Confounded_Face': {
			status: 1,
			path: 'new/Confounded Face.png',
		},
		'Confused_Face': {
			status: 1,
			path: 'new/Confused Face.png',
		},
		'Cowboy_Hat_Face': {
			status: 1,
			path: 'new/Cowboy Hat Face.png',
		},
		'Crossed_Fingers': {
			status: 1,
			path: 'new/Crossed Fingers.png',
		},
		'Crying_Cat': {
			status: 1,
			path: 'new/Crying Cat.png',
		},
		'Crying_Face': {
			status: 1,
			path: 'new/Crying Face.png',
		},
		'Disappointed_Face': {
			status: 1,
			path: 'new/Disappointed Face.png',
		},
		'Disguised_Face': {
			status: 1,
			path: 'new/Disguised Face.png',
		},
		'Dizzy_Face': {
			status: 1,
			path: 'new/Dizzy Face.png',
		},
		'Downcast_Face_with_Sweat': {
			status: 1,
			path: 'new/Downcast Face with Sweat.png',
		},
		'Drooling_Face': {
			status: 1,
			path: 'new/Drooling Face.png',
		},
		'Ea': {
			status: 1,
			path: 'new/Ea.png',
		},
		'Ear_with_Hearing_Aid': {
			status: 1,
			path: 'new/Ear with Hearing Aid.png',
		},
		'Exploding_Head': {
			status: 1,
			path: 'new/Exploding Head.png',
		},
		'Expressionless_Face_': {
			status: 1,
			path: 'new/Expressionless Face .png',
		},
		'Eye': {
			status: 1,
			path: 'new/Eye.png',
		},
		'Eyes': {
			status: 1,
			path: 'new/Eyes.png',
		},
		'Face_Blowing_a_Kiss': {
			status: 1,
			path: 'new/Face Blowing a Kiss.png',
		},
		'Face_Exhaling': {
			status: 1,
			path: 'new/Face Exhaling.png',
		},
		'Face_Savoring_Food': {
			status: 1,
			path: 'new/Face Savoring Food.png',
		},
		'Face_Screaming_in_Fear': {
			status: 1,
			path: 'new/Face Screaming in Fear.png',
		},
		'Face_Vomiting': {
			status: 1,
			path: 'new/Face Vomiting.png',
		},
		'Face_Without_Mouth': {
			status: 1,
			path: 'new/Face Without Mouth.png',
		},
		'Face_in_Clouds': {
			status: 1,
			path: 'new/Face in Clouds.png',
		},
		'Face_with_Hand_Over_Mouth': {
			status: 1,
			path: 'new/Face with Hand Over Mouth.png',
		},
		'Face_with_Head-Bandage': {
			status: 1,
			path: 'new/Face with Head-Bandage.png',
		},
		'Face_with_Medical_Mask': {
			status: 1,
			path: 'new/Face with Medical Mask.png',
		},
		'Face_with_Monocle': {
			status: 1,
			path: 'new/Face with Monocle.png',
		},
		'Face_with_Open_Mouth': {
			status: 1,
			path: 'new/Face with Open Mouth.png',
		},
		'Face_with_Raised_Eyebrow': {
			status: 1,
			path: 'new/Face with Raised Eyebrow.png',
		},
		'Face_with_Rolling_Eyes': {
			status: 1,
			path: 'new/Face with Rolling Eyes.png',
		},
		'Face_with_Steam_From_Nose': {
			status: 1,
			path: 'new/Face with Steam From Nose.png',
		},
		'Face_with_Symbols_on_Mouth': {
			status: 1,
			path: 'new/Face with Symbols on Mouth.png',
		},
		'Face_with_Tears_of_Joy': {
			status: 1,
			path: 'new/Face with Tears of Joy.png',
		},
		'Face_with_Thermometer': {
			status: 1,
			path: 'new/Face with Thermometer.png',
		},
		'Face_with_Tongue': {
			status: 1,
			path: 'new/Face with Tongue.png',
		},
		'Fearful_Face': {
			status: 1,
			path: 'new/Fearful Face.png',
		},
		'Flexed_Biceps': {
			status: 1,
			path: 'new/Flexed Biceps.png',
		},
		'Flushed_Face': {
			status: 1,
			path: 'new/Flushed Face.png',
		},
		'Folded_Hands': {
			status: 1,
			path: 'new/Folded Hands.png',
		},
		'Foot': {
			status: 1,
			path: 'new/Foot.png',
		},
		'Frowning_Fac': {
			status: 1,
			path: 'new/Frowning Fac.png',
		},
		'Frowning_Face_with_Open_Mouth': {
			status: 1,
			path: 'new/Frowning Face with Open Mouth.png',
		},
		'Ghost': {
			status: 1,
			path: 'new/Ghost.png',
		},
		'Goblin': {
			status: 1,
			path: 'new/Goblin.png',
		},
		'Grimacing_Face': {
			status: 1,
			path: 'new/Grimacing Face.png',
		},
		'Grinning_Cat_with_Smiling_Eyes': {
			status: 1,
			path: 'new/Grinning Cat with Smiling Eyes.png',
		},
		'Grinning_Cat': {
			status: 1,
			path: 'new/Grinning Cat.png',
		},
		'Grinning_Face_with_Big_Eyes': {
			status: 1,
			path: 'new/Grinning Face with Big Eyes.png',
		},
		'Grinning_Face_with_Smiling_Eyes': {
			status: 1,
			path: 'new/Grinning Face with Smiling Eyes.png',
		},
		'Grinning_Face_with_Sweat': {
			status: 1,
			path: 'new/Grinning Face with Sweat.png',
		},
		'Grinning_Face': {
			status: 1,
			path: 'new/Grinning Face.png',
		},
		'Grinning_Squinting_Face': {
			status: 1,
			path: 'new/Grinning Squinting Face.png',
		},
		'Hand_with_Fingers_Splayed': {
			status: 1,
			path: 'new/Hand with Fingers Splayed.png',
		},
		'Hot_Face-1': {
			status: 1,
			path: 'new/Hot Face-1.png',
		},
		'Hot_Face': {
			status: 1,
			path: 'new/Hot Face.png',
		},
		'Hugging_Face': {
			status: 1,
			path: 'new/Hugging Face.png',
		},
		'Hushed_Face': {
			status: 1,
			path: 'new/Hushed Face.png',
		},
		'Index_Pointing_Up': {
			status: 1,
			path: 'new/Index Pointing Up.png',
		},
		'Kiss_Mark': {
			status: 1,
			path: 'new/Kiss Mark.png',
		},
		'Kissing_Cat': {
			status: 1,
			path: 'new/Kissing Cat.png',
		},
		'Kissing_Face_with_Closed_Eyes': {
			status: 1,
			path: 'new/Kissing Face with Closed Eyes.png',
		},
		'Kissing_Face_with_Smiling_Eyes': {
			status: 1,
			path: 'new/Kissing Face with Smiling Eyes.png',
		},
		'Kissing_Face': {
			status: 1,
			path: 'new/Kissing Face.png',
		},
		'Left-Facing_Fist': {
			status: 1,
			path: 'new/Left-Facing Fist.png',
		},
		'Leg': {
			status: 1,
			path: 'new/Leg.png',
		},
		'Loudly_Crying_Face': {
			status: 1,
			path: 'new/Loudly Crying Face.png',
		},
		'Love-You_Gesture': {
			status: 1,
			path: 'new/Love-You Gesture.png',
		},
		'Lungs': {
			status: 1,
			path: 'new/Lungs.png',
		},
		'Lying_Face': {
			status: 1,
			path: 'new/Lying Face.png',
		},
		'Middle_Finger': {
			status: 1,
			path: 'new/Middle Finger.png',
		},
		'Money-Mouth_Face': {
			status: 1,
			path: 'new/Money-Mouth Face.png',
		},
		'Mouth': {
			status: 1,
			path: 'new/Mouth.png',
		},
		'Nail_Polish': {
			status: 1,
			path: 'new/Nail Polish.png',
		},
		'Nauseated_Face': {
			status: 1,
			path: 'new/Nauseated Face.png',
		},
		'Nerd_Face': {
			status: 1,
			path: 'new/Nerd Face.png',
		},
		'Neutral_Face': {
			status: 1,
			path: 'new/Neutral Face.png',
		},
		'Nose': {
			status: 1,
			path: 'new/Nose.png',
		},
		'OK_Hand': {
			status: 1,
			path: 'new/OK Hand.png',
		},
		'Ogre': {
			status: 1,
			path: 'new/Ogre.png',
		},
		'Oncoming_Fist': {
			status: 1,
			path: 'new/Oncoming Fist.png',
		},
		'Open_Hands': {
			status: 1,
			path: 'new/Open Hands.png',
		},
		'Palms_Up_Together': {
			status: 1,
			path: 'new/Palms Up Together.png',
		},
		'Partying_Fac': {
			status: 1,
			path: 'new/Partying Fac.png',
		},
		'Pensive_Face': {
			status: 1,
			path: 'new/Pensive Face.png',
		},
		'Persevering_Face': {
			status: 1,
			path: 'new/Persevering Face.png',
		},
		'Pile_of_Poo': {
			status: 1,
			path: 'new/Pile of Poo.png',
		},
		'Pinched_Fingers': {
			status: 1,
			path: 'new/Pinched Fingers.png',
		},
		'Pinching_Hand': {
			status: 1,
			path: 'new/Pinching Hand.png',
		},
		'Pleading_Face': {
			status: 1,
			path: 'new/Pleading Face.png',
		},
		'Pouting_Cat': {
			status: 1,
			path: 'new/Pouting Cat.png',
		},
		'Pouting_Face': {
			status: 1,
			path: 'new/Pouting Face.png',
		},
		'Raised_Back_of_Hand': {
			status: 1,
			path: 'new/Raised Back of Hand.png',
		},
		'Raised_Fist': {
			status: 1,
			path: 'new/Raised Fist.png',
		},
		'Raised_Hand': {
			status: 1,
			path: 'new/Raised Hand.png',
		},
		'Raising_Hands': {
			status: 1,
			path: 'new/Raising Hands.png',
		},
		'Relieved_Face': {
			status: 1,
			path: 'new/Relieved Face.png',
		},
		'Right-Facing_Fist': {
			status: 1,
			path: 'new/Right-Facing Fist.png',
		},
		'Robot': {
			status: 1,
			path: 'new/Robot.png',
		},
		'Rolling_on_the_Floor_Laughing': {
			status: 1,
			path: 'new/Rolling on the Floor Laughing.png',
		},
		'Sad_but_Relieved_Face': {
			status: 1,
			path: 'new/Sad but Relieved Face.png',
		},
		'Selfie': {
			status: 1,
			path: 'new/Selfie.png',
		},
		'Shushing_Face': {
			status: 1,
			path: 'new/Shushing Face.png',
		},
		'Sign_of_the_Horns': {
			status: 1,
			path: 'new/Sign of the Horns.png',
		},
		'Skull_and_Crossbones': {
			status: 1,
			path: 'new/Skull and Crossbones.png',
		},
		'Skull': {
			status: 1,
			path: 'new/Skull.png',
		},
		'Sleeping_Face': {
			status: 1,
			path: 'new/Sleeping Face.png',
		},
		'Sleepy_Face': {
			status: 1,
			path: 'new/Sleepy Face.png',
		},
		'Slightly_Frowning_FaceSlightly_Frowning_FaceSlightly_Frowning_FaceSlightly_Frowning_FaceSlightly_Frowning_FaceSlightly_Frowning_Face': {
			status: 1,
			path: 'new/Slightly Frowning FaceSlightly Frowning FaceSlightly Frowning FaceSlightly Frowning FaceSlightly Frowning FaceSlightly Frowning Face.png',
		},
		'Slightly_Smiling_Face': {
			status: 1,
			path: 'new/Slightly Smiling Face.png',
		},
		'Smiling_Cat_with_Heart-Eyes': {
			status: 1,
			path: 'new/Smiling Cat with Heart-Eyes.png',
		},
		'Smiling_Face_with_Halo': {
			status: 1,
			path: 'new/Smiling Face with Halo.png',
		},
		'Smiling_Face_with_Heart-Eyes': {
			status: 1,
			path: 'new/Smiling Face with Heart-Eyes.png',
		},
		'Smiling_Face_with_Hearts': {
			status: 1,
			path: 'new/Smiling Face with Hearts.png',
		},
		'Smiling_Face_with_Horns': {
			status: 1,
			path: 'new/Smiling Face with Horns.png',
		},
		'Smiling_Face_with_Smiling_Eyes': {
			status: 1,
			path: 'new/Smiling Face with Smiling Eyes.png',
		},
		'Smiling_Face_with_Sunglasses': {
			status: 1,
			path: 'new/Smiling Face with Sunglasses.png',
		},
		'Smiling_Face_with_Tear': {
			status: 1,
			path: 'new/Smiling Face with Tear.png',
		},
		'Smiling_Face': {
			status: 1,
			path: 'new/Smiling Face.png',
		},
		'Smirking_Fac': {
			status: 1,
			path: 'new/Smirking Fac.png',
		},
		'Sneezing_Face': {
			status: 1,
			path: 'new/Sneezing Face.png',
		},
		'Star-Struck': {
			status: 1,
			path: 'new/Star-Struck.png',
		},
		'Thinking_Face': {
			status: 1,
			path: 'new/Thinking Face.png',
		},
		'Thumbs_Down': {
			status: 1,
			path: 'new/Thumbs Down.png',
		},
		'Thumbs_Up': {
			status: 1,
			path: 'new/Thumbs Up.png',
		},
		'Tired_Face': {
			status: 1,
			path: 'new/Tired Face.png',
		},
		'Tongu': {
			status: 1,
			path: 'new/Tongu.png',
		},
		'Tooth': {
			status: 1,
			path: 'new/Tooth.png',
		},
		'Unamused_Fac': {
			status: 1,
			path: 'new/Unamused Fac.png',
		},
		'Upside-Down_Face': {
			status: 1,
			path: 'new/Upside-Down Face.png',
		},
		'Victory_Hand': {
			status: 1,
			path: 'new/Victory Hand.png',
		},
		'Vulcan_Salute': {
			status: 1,
			path: 'new/Vulcan Salute.png',
		},
		'Waving_Hand': {
			status: 1,
			path: 'new/Waving Hand.png',
		},
		'Weary_Cat': {
			status: 1,
			path: 'new/Weary Cat.png',
		},
		'Weary_Face': {
			status: 1,
			path: 'new/Weary Face.png',
		},
		'Winking_Face_with_Tongue': {
			status: 1,
			path: 'new/Winking Face with Tongue.png',
		},
		'Winking_Face': {
			status: 1,
			path: 'new/Winking Face.png',
		},
		'Woozy_Face': {
			status: 1,
			path: 'new/Woozy Face.png',
		},
		'Worried_Face': {
			status: 1,
			path: 'new/Worried Face.png',
		},
		'Yawning_Face': {
			status: 1,
			path: 'new/Yawning Face.png',
		},
		'Zany_Face': {
			status: 1,
			path: 'new/Zany Face.png',
		},
		'Zipper-Mouth_Face': {
			status: 1,
			path: 'new/Zipper-Mouth Face.png',
		},
		'image_225': {
			status: 1,
			path: 'new/image 225.png',
		},
		'image_227': {
			status: 1,
			path: 'new/image 227.png',
		},
		'image_228': {
			status: 1,
			path: 'new/image 228.png',
		},
		'image_359': {
			status: 1,
			path: 'new/image 359.png',
		},
		'image_361': {
			status: 1,
			path: 'new/image 361.png',
		},
		'image_42': {
			status: 1,
			path: 'new/image 42.png',
		},
	}
}
for(let idx in face.faceObject) {
	if (face.faceObject[idx].status === 1) {
		face.faces.push({
			key: idx,
			status: face.faceObject[idx].status,
			path: face.faceObject[idx].path,
		})
	}
}
module.exports = face
<template>
  <el-dialog
    title="交易记录"
    :visible.sync="dialogVisible"
    center
    width="800px"
    :before-close="handleClose">
    <el-button type="primary" @click="fetchData" style="margin-bottom: 10px">刷 新</el-button>
    <el-table
      height="300"
      :data="datas"
      border>
      <el-table-column
        prop="create_time"
        label="单号/日期"
        width="170">
        <template slot-scope="scope">
          <span style="color: #000;">{{scope.row.order_sn}}</span><br>
          <span style="font-size: 12px">{{scope.row.create_time}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="trade_status"
        label="交易状态"
        align="center"
        width="80">
        <template slot-scope="scope">
          <el-popover
            placement="right"
            width="80"
            trigger="click"
            :placement="`bottom-start`"
            v-if="scope.row.status == 1"
          >
            <el-button type="success" @click="handleSwitchTradeStatus(scope.row, 3)" size="mini" style="margin-bottom: 5px">盈利</el-button><br>
            <el-button type="warning" @click="handleSwitchTradeStatus(scope.row, 4)" size="mini">亏损</el-button>
            <el-button slot="reference" :type="scope.row.trade_status_type" size="mini" v-popover:popoverUser>
              {{ scope.row.trade_status_str }}
            </el-button>
          </el-popover>
          <div v-else>{{ scope.row.trade_status_str }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="user_id"
        label="UserID"
        width="120">
      </el-table-column>
      <el-table-column
        prop="title"
        label="交易币"
        width="120">
      </el-table-column>
      <el-table-column
        prop="amount"
        label="规格"
        width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.buy_up === 1" style="color: green">涨 {{scope.row.time}} S</span>
          <span v-else style="color: red">跌 {{scope.row.time}} S</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="amount"
        label="金额"
        width="120">
      </el-table-column>
      <el-table-column
        prop="chain_price"
        label="即时价"
        width="120">
      </el-table-column>
      <el-table-column
        prop="settle_price"
        label="结算价"
        width="120">
      </el-table-column>
      <el-table-column
        prop="status_str"
        label="状态">
      </el-table-column>
      <el-table-column
        prop="earning"
        label="收益">
      </el-table-column>
      <el-table-column
        fixed="right"
        prop="earning"
        label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="showEdit(scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <br/>
    <el-pagination
      align="right"
      background
      layout="prev, pager, next"
      :current-page.sync="searchForm.page"
      @current-change="handleCurrentChange"
      :total="searchForm.total"
      :page-size="searchForm.limit">
    </el-pagination>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="fetchData">刷 新</el-button>
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </div>
    <dialog-order-edit ref="dialogOrderEdit" @change="fetchData"></dialog-order-edit>
  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";
import DialogOrderEdit from "@/views/chat/components/dialog-order-edit";

export default {
  name: "DialogOrderLog",
  components: {
    DialogOrderEdit
  },
  data() {
    return {
      dialogVisible: false,
      userId: 0,
      searchForm: {
        user_id: 0,
        page: 1,
        total: 0,
        limit: 20
      },
      datas: []
    }
  },
  methods: {
    toggle(userId) {
      this.userId = userId
      this.searchForm.user_id = userId
      this.dialogVisible = true
      this.fetchData()
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleCurrentChange(e) {
      console.log(e)
      this.fetchData()
    },
    fetchData() {
      const that = this
      that.datas = [];
      requestHttp('agent/get_order_log', this.searchForm).then((res) => {
        if (res.code == 200) {
          that.datas = res.data.data
          that.searchForm.page = res.data.current_page
          that.searchForm.total = res.data.total
          that.searchForm.limit = res.data.per_page
        } else {
          that.$message.error(res.message)
        }
      })
    },
    handleSwitchTradeStatus(row, status) {
      const statusArr = {
        3: '盈利',
        4: '亏损',
      }
      const that = this
      this.$confirm('确认设置交易状态为' + statusArr[status] + '？', '提示').then(function () {
        requestHttp('agent/switch_order_trade_status', {
          'id': row.id,
          'trade_status': status
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success({ message: res.message, offset: 100 })
            that.fetchData()
          } else {
            that.$message.error({ message: res.message, offset: 100 })
          }
        })
      })
    },
    showEdit(row) {
      this.$refs.dialogOrderEdit.toggle(row.id)
    }
  }
}
</script>

<style scoped>

</style>
